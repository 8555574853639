import {
	FETCH_PAYMENT_REQUEST,
	FETCH_PAYMENT_SUCCESS,
	FETCH_PAYMENT_FAILURE,
} from "../action-types";

const initialState = {
	payments: [],
	loading: false,
	error: "",
};

const paymentReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_PAYMENT_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_PAYMENT_SUCCESS:
			return {
				...state,
				payments: payload,
				loading: false,
			};
		case FETCH_PAYMENT_FAILURE:
			return {
				...state,
				payments: [],
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
};

export default paymentReducer;
