import { API_URL } from "./url";

// LOGIN URL
export const LOGIN_USER = `${API_URL}/user/login`;
export const LOGOUT_USER = `${API_URL}/user/logout`;

// WIN-LOSS URL
export const GET_ALL_WIN_LOSS = `${API_URL}/winloss`;
export const GET_WIN_LOSS_BY = `${API_URL}/winloss/info`;
export const UPDATE_WIN_LOSS = `${API_URL}/winloss/update`;

// COMPANY URL
export const GET_ALL_COMPANY = `${API_URL}/companyInfo`; // all venues->hall->venue employee->menu->customers->event->bookings
export const GET_COMPANY_COUNT = `${API_URL}/analytics/company`;
export const GET_COMPANY_BY_ID = `${API_URL}/companyInfo/id`;
export const UPDATE_COMPANY = `${API_URL}/companyInfo/update`;

// PAYMENT URL
export const GET_COMPANY_PAYMENT = `${API_URL}/payment/amount`; // payment, refund, discount
export const GET_ISSUES_LIST = `${API_URL}/support`; // payment, refund, discount
