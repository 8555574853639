import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Table from "../../components/table/Table";
import { getAllWinloss } from "../../redux/actions/winloss-action";

// import { getAllWinloss } from "../../redux/index";

export default function Winloss() {
	const dispatch = useDispatch();
	const winLossData = useSelector((state) => state.winLoss.winloss);

	useEffect(() => {
		async function fetchWinlossData() {
			await dispatch(getAllWinloss());
		}

		fetchWinlossData();
	}, [dispatch]);

	const tableConstants = [
		{
			title: "Company Name",
			render: (rowData) => {
				return <span>{rowData.company_name}</span>;
			},
		},
		{
			title: "Name",
			render: (rowData) => {
				return <span>{rowData.name}</span>;
			},
		},
		{
			title: "Email",
			render: (rowData) => {
				return <span>{rowData.email}</span>;
			},
		},
		{
			title: "Phone",
			render: (rowData) => {
				return <span>{rowData.phone}</span>;
			},
		},
		{
			title: "Venue Number",
			render: (rowData) => {
				return <span>{rowData.venue_number}</span>;
			},
		},
		{
			title: "Loss",
			render: (rowData) => {
				return <span>{rowData.loss}</span>;
			},
		},
		// {
		//     title: "Action",
		//     render: (rowData) => {
		//         return (
		//             <button
		//                 className="btn btn-warning"
		//                 onClick={handleEdit(rowData)}
		//             >
		//                 Edit
		//             </button>
		//         );
		//     },
		// },
	];

	return (
		<div className="main-content">
			<Table
				cols={tableConstants}
				data={winLossData}
				isDark
				bordered
				striped
				hoverable
			/>
		</div>
	);
}
