import {
	FETCH_SUPPORT_REQUEST,
	FETCH_SUPPORT_SUCCESS,
	FETCH_SUPPORT_FAILURE,
} from "../action-types";

import axios from "axios";
import { GET_ISSUES_LIST } from "../../constants/endpoints";

import setAuthToken from "../../helpers/setAuthToken";

import showToastMessage, {
	isEmptyObject,
	sessionInfo,
} from "../../helpers/functions";

async function _handleError(error, dispatch) {
	var status = 504;

	if (!isEmptyObject(error.response)) {
		status = error.response.status;
	}

	if (status === 403) {
		// history.push({ pathname: "/" });dispatch();
		await dispatch({
			type: FETCH_SUPPORT_FAILURE,
			error: error.response.data.message,
		});
		showToastMessage(error.response.data.message, "E");
	} else if (status === 400) {
		showToastMessage(error.response.data.message, "E");
		await dispatch({
			type: FETCH_SUPPORT_FAILURE,
			error: error.response.data.message,
		});
	} else {
		showToastMessage(error.message, "E");
		await dispatch({
			type: FETCH_SUPPORT_FAILURE,
			error: error.message,
		});
	}
}

export const getSupportList = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_SUPPORT_REQUEST,
	});

	await axios
		.get(GET_ISSUES_LIST)
		.then((response) => {
			if (response.data.success) {
				dispatch({
					type: FETCH_SUPPORT_SUCCESS,
					payload: response.data.result,
				});
			} else {
				showToastMessage(response.data.message, "E");
				dispatch({
					type: FETCH_SUPPORT_FAILURE,
					error: response.data.message,
				});
			}
		})
		.catch((err) => {
			_handleError(err, dispatch);
		});
};
