export const FETCH_PAYMENT_REQUEST = "FETCH_PAYMENT_REQUEST";
export const FETCH_PAYMENT_SUCCESS = "FETCH_PAYMENT_SUCCESS";
export const FETCH_PAYMENT_FAILURE = "FETCH_PAYMENT_FAILURE";

export const GET_COMPANY_PAYMENT = "GET_COMPANY_PAYMENT";

export const FETCH_COMPANY_REQUEST = "FETCH_COMPANY_REQUEST";
export const FETCH_COMPANY_FAILURE = "FETCH_COMPANY_FAILURE";
export const FETCH_COMPANY_SUCCESS = "FETCH_COMPANY_SUCCESS";
export const UPDATE_COMPANY = "UPDATE_COMPANY";
export const FETCH_COMPANY_COUNT_SUCCESS = "FETCH_COMPANY_COUNT_SUCCESS";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const FETCH_USER_LOGOUT = "FETCH_USER_LOGOUT";

export const FETCH_WINLOSS_REQUEST = "FETCH_WINLOSS_REQUEST";
export const FETCH_WINLOSS_SUCCESS = "FETCH_WINLOSS_SUCCESS";
export const FETCH_WINLOSS_FAILURE = "FETCH_WINLOSS_FAILURE";
export const UPDATE_WIN_LOSS = "UPDATE_WIN_LOSS";

export const FETCH_SUPPORT_REQUEST = "FETCH_SUPPORT_REQUEST";
export const FETCH_SUPPORT_SUCCESS = "FETCH_SUPPORT_SUCCESS";
export const FETCH_SUPPORT_FAILURE = "FETCH_WINLOSS_FAILURE";
