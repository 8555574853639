import {
	FETCH_USER_REQUEST,
	FETCH_USER_SUCCESS,
	FETCH_USER_FAILURE,
	FETCH_USER_LOGOUT,
} from "../action-types";

const initialState = {
	user: {},
	isLoading: false,
	error: "",
};

const userReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_USER_REQUEST:
			return {
				...state,
				isLoading: true,
			};
		case FETCH_USER_SUCCESS:
			return {
				...state,
				user: payload,
				isLoading: false,
			};
		case FETCH_USER_FAILURE:
			return {
				...state,
				error: payload,
				isLoading: false,
			};
		case FETCH_USER_LOGOUT:
			return {
				isLoading: false,
				user: {},
				error: payload,
			};
		default:
			return state;
	}
};

export default userReducer;
