import "./App.scss";
// setting up the redux
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login/login.page.jsx";
import Dashboard from "./pages/Dashboard/dashboard.page";
import Company from "./pages/Company/company.page.jsx";
import Winloss from "./pages/Winloss/winloss.page.jsx";

import ProtectedRoute from "./routes/ProtectedRoute";
import history from "./helpers/history";
import SupportIssuesPage from "./pages/Support/support-issues.page";

function App() {
	return (
		<Provider store={store}>
			<div className="App">
				<Router history={history}>
					<Switch>
						<Route exact path="/" component={Login} />
						<ProtectedRoute
							exact
							path="/dashboard"
							component={Dashboard}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/company"
							component={Company}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/winloss"
							component={Winloss}
							navigation={true}
						/>
						<ProtectedRoute
							exact
							path="/support"
							component={SupportIssuesPage}
							navigation={true}
						/>
					</Switch>
				</Router>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable={false}
					pauseOnHover
				/>
			</div>
		</Provider>
	);
}

export default App;
