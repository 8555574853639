import { Button } from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import SelectBox from "../../components/select-box/select-box.input";
import TextInput from "../../components/text-input/text-input-error.component";
import {
	issuePage,
	supportStatus,
	supportType,
} from "../../constants/variables";
import { getDateDuration } from "../../helpers/functions";
import { getSupportList } from "../../redux/actions/support-action";

import { FE_URL } from "../../constants/url";
import { Link } from "react-router-dom";

function SupportIssuesPage() {
	const dispatch = useDispatch();
	const { supportList } = useSelector((state) => state.support);
	const { control, handleSubmit, reset } = useForm();

	useEffect(() => {
		dispatch(getSupportList());
	}, [dispatch]);

	const _searchSupport = (value) => {
		console.log(value);
	};

	return (
		<div className="main-content">
			<div className="filter-wrapper">
				<div className="filter-container">
					<Controller
						name="support_page"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<SelectBox
								// id="outlined-size-small email"
								className="search"
								label="Issue Page"
								field={field}
								size="small"
								options={issuePage}
							/>
						)}
					/>
					<Controller
						name="support_type"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<SelectBox
								// id="outlined-size-small email"
								className="search"
								label="Support Type"
								field={field}
								size="small"
								options={supportType}
							/>
						)}
					/>
					<Controller
						name="support_status"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<SelectBox
								// id="outlined-size-small email"
								className="search"
								label="Support Status"
								field={field}
								size="small"
								options={supportStatus}
							/>
						)}
					/>

					<Controller
						name="date_to"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								id="outlined-size-small email"
								className="login-input search"
								type="date"
								field={field}
								size="small"
								placeholder=""
							/>
						)}
					/>

					<Controller
						name="date_to"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								id="outlined-size-small email"
								className="login-input search"
								type="date"
								field={field}
								size="small"
							/>
						)}
					/>
				</div>
				<div className="filter-button-container">
					<Button variant="contained" onClick={() => reset()}>
						Clear
					</Button>
					<Button
						variant="contained"
						onClick={handleSubmit(_searchSupport)}
					>
						Search
					</Button>
				</div>
			</div>
			<div className="table-responsive">
				<table className="table table-responsive table-bordered table-hover table-striped text-capitalize">
					<thead>
						<tr>
							<th>#</th>
							<th>Company Name</th>
							<th>User</th>
							<th>Issue Page</th>
							<th>Support Type</th>
							<th>Support While</th>
							<th>Support Status</th>
							<th>Access</th>
							<th>Issued Date</th>
						</tr>
					</thead>
					<tbody>
						{supportList?.map((support, index) => (
							<tr key={index}>
								<td>{index + 1}</td>
								<td>
									{support?.company_info?.company_name} -{" "}
									{support?.company_info?.company_phone}
								</td>

								<td>
									{support?.user?.first_name}{" "}
									{support.user.last_name} -{" "}
									{support.user.user_mobile} - {""}
									{support.support_call && "CALL"}
								</td>
								<td>{support.support_page}</td>
								<td>{support.support_type}</td>
								<td>{support.support_while}</td>
								<td>{support.support_status}</td>
								<td>
									{support.grant_access ? (
										<Link
											to={{
												pathname: `${FE_URL}?user=${support.user_info_id}&company=${support.company_info_id}`,
											}}
											target="_blank"
										>
											<button className="btn btn-success">
												View
											</button>
										</Link>
									) : (
										"-"
									)}
								</td>
								<td>
									{moment(support.createdAt).format(
										"YYYY-MM-DD HH:mm:ss"
									)}{" "}
									[{getDateDuration(support.createdAt)} day/s]
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default SupportIssuesPage;
