import { combineReducers } from "redux";
import loginReducer from "./reducers/login-reducer";
import companyReducer from "./reducers/company-reducer";
import paymentReducer from "./reducers/payment-reducer";
import winlossReducer from "./reducers/winloss-reducer";
import supportReducer from "./reducers/support-reducer";

const rootReducer = combineReducers({
	company: companyReducer,
	payment: paymentReducer,
	login: loginReducer,
	winLoss: winlossReducer,
	support: supportReducer,
});

export default rootReducer;
