import axios from "axios";
import { GET_ALL_COMPANY, GET_COMPANY_COUNT } from "../../constants/endpoints";

import {
	FETCH_COMPANY_REQUEST,
	FETCH_COMPANY_SUCCESS,
	FETCH_COMPANY_FAILURE,
	FETCH_COMPANY_COUNT_SUCCESS,
} from "../action-types.js";

import setAuthToken from "../../helpers/setAuthToken";
import { sessionInfo } from "../../helpers/functions";

export const getAllCompanies = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_COMPANY_REQUEST,
	});

	try {
		const response = await axios.get(GET_ALL_COMPANY);

		dispatch({
			type: FETCH_COMPANY_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_COMPANY_FAILURE,
			error: err.message,
		});
	}
};

export const getCompaniesCount = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_COMPANY_REQUEST,
	});

	try {
		const response = await axios.get(GET_COMPANY_COUNT);
		console.log(response);
		dispatch({
			type: FETCH_COMPANY_COUNT_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_COMPANY_FAILURE,
			error: err.message,
		});
	}
};
