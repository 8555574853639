import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import jwt_decode from "jwt-decode";
const { JWK, parse } = require("node-jose");

const decrypt = async (encryptedBody) => {
	// let _privateKey = process.env.PRIVATE_KEY;
	let _privateKey = `-----BEGIN RSA PRIVATE KEY-----
MIIEowIBAAKCAQEAqfa2v45/442A70xBQ+Gv10Npxx7LFJQtfM6PdAX8UR/+uy7D
SVe/taZmY4JK+qipjVDppDhB4LIoUYFgHjp1fCEQW/UC8uLtuUhupDln0jkBrNjf
4FRFHLbVG+pF30JHdBccy7CPIlJqceupy0UqJsYk8KmcmQX0HDuiNX/FUy1J10Tk
axqoCvF16xoGGyFFKGs8cV8DjlzskDWRBpslZlXV2EgYn17/nHp7xAltiCRx982U
BB6AjM8kcXtHvQy5mMT7Ygzo8Laasxr7m9kTaCictaANnWBq5Xu7OzkJ+tZB8ko3
66KaYwh+Bpk2N3JSUVk2thVXeoTxU3no92aafwIDAQABAoIBAEm7pPYfDK4cAhZN
/3ZCXzVEoovmePVZfw5I0gTkX9yVw71SgRe9QIa+RGbg7EQnjT6oHmPGoBWpVEJf
nOIgPD14Xo92ECxsc/LQUGdgHAEgB/EDOMQp6r2VN4hfrqY3K6ZaLHzVAJNEK7d2
I/fx7mJZzUa2XiAobAkfebQ51NHK+8uRU73JS6ruGoAlvRl/ig0Cb6DJ/KhHxtk1
n8kasfACfIgICcgLNfU5Z+s7HDgpWfVB6CBD5lHnU3xkN3FBZrqUOgrfleMS+TH/
ZbvUAoRb4wSuanzYE+jmxuSAe1Q1UUbWrr2Y/6oD/Bgz0VpKBIr6MvKWZfomAp7u
gGF7GNkCgYEA15YGilNoBjkIHc3BMmpl0W7IJLk1N2bm/wgDaaGYGzU7c/J6CoIS
1if9Dzzk6nmnTGjXso1O8qbax6Q0xeSRZ2dDfhZazNgsz+K5AonCQTrMaBh7gmWO
MLHqkowTCdreeMTfgA4h0qtIC32iFYvm2XRw58g4uT3Z9wOiiqeKjAMCgYEAydNG
AwLqZn92KrjP83SKwtcxlEqO1Ijh/utbA+b5mA54B+LvuUaOGZ8jy1ZEa0GCxfa6
C0Kjzmyt7X5o6fnA/LUICNDTtz4D5YMKMV9LP+rw7zaCIe3PZKpqwfO9Tz0y6+Mt
WVhVG24i2Eyn1xPNP+3WeLhGO6KtXbE/i3o6tNUCgYBEof5zCxqS4JU058wZwMWp
ZxtQlaXbfWcMygBMIKXMA6Zpw0gXEFHu28w0ja5S92fLa5U/wrzfgz8jT6N9XMbt
pHJYMcW2cRvTEtrTdAfjvFRWz3SCjtI1rm5/ORjWildJG2QL4HPkHaNSBS0UrvVP
RUKsh5GHZ6l8eivE+JGjAQKBgQCpxfB57gTxjb0AORCssddcCRtqq8E0ZaT+BEs+
oBhLYbh0oeCYERLN2MGKdZn+Q1BXaLGdcURMC73PLuvgDjjaGwqUJ95AoYkzkqSL
Xab2MJLWFxsmoV45RuWhG4Ce3FMufJ6ULqsA9QeYvfreAIlCgrZMPG7QEYsWaliS
4pHptQKBgHuV7H+YhuPHxmh0DXXPvR65Z2psGpgwjbR6+jUWY/OikUNNd03bhqdZ
DXrJBo6RTh3nzrE8yRTge+gaKcKfsxqHKedxmCQS7LPOsp9y2WfVGbl+e5BxXOv7
H4IUuxkVspMx5PMk3niSkH4oDGFmFLBBTM9cQ0tx5Sl8+GeRSlWv
-----END RSA PRIVATE KEY-----
	`;

	let keystore = JWK.createKeyStore();
	await keystore.add(await JWK.asKey(_privateKey, "pem"));
	let outPut = parse.compact(encryptedBody);
	let decryptedVal = await outPut.perform(keystore);
	let claims = Buffer.from(decryptedVal.plaintext).toString();
	return claims;
};

export async function sessionInfo() {
	let encryptedToken = localStorage.getItem("fooventToken") || false;

	if (encryptedToken) {
		const jwtToken = await decrypt(encryptedToken);
		const token = jwtToken.replace(/"/g, "");
		const sessionData = await jwt_decode(jwtToken);

		// let s = jwt_decode(token); // old token

		return { ...sessionData, token };
	} else {
		return 0;
	}
}

export function isEmptyObject(obj) {
	for (var key in obj) {
		if (obj.hasOwnProperty(key)) return false;
	}
	return true;
}

export default function showToastMessage(messageText, messageType = "I") {
	toast.dismiss();
	if (messageType === "S") {
		toast.success(messageText);
	}
	if (messageType === "I") {
		toast.info(messageText);
	}
	if (messageType === "E") {
		toast.error(messageText);
	}
	if (messageType === "W") {
		toast.warning(messageText);
	}
}

export function getDateDuration(startDate) {
	var now = moment(new Date()); //todays date
	var start = moment(startDate).format("YYYY-MM-DD HH:mm:ss"); // another date
	var duration = moment.duration(now.diff(start));
	var days = duration.asDays();
	return days.toFixed();
}
