import {
	FETCH_COMPANY_REQUEST,
	FETCH_COMPANY_SUCCESS,
	FETCH_COMPANY_FAILURE,
	FETCH_COMPANY_COUNT_SUCCESS,
} from "../action-types";

const initialState = {
	companies: [],
	companyCount: {},
	loading: false,
	error: "",
};

const companyReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case FETCH_COMPANY_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_COMPANY_SUCCESS:
			return {
				...state,
				companies: payload,
				loading: false,
			};
		case FETCH_COMPANY_COUNT_SUCCESS:
			return {
				...state,
				companyCount: payload,
				loading: false,
			};
		case FETCH_COMPANY_FAILURE:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
};

export default companyReducer;
