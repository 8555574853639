import "./dashboard.page.scss";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardBox from "../../components/dashboard-box/dashboard-box";

import {
	// getAllCompanies,
	getCompaniesCount,
} from "../../redux/actions/company-action";

export default function Dashboard() {
	const dispatch = useDispatch();
	const companyCount = useSelector((state) => state.company.companyCount);

	useEffect(() => {
		dispatch(getCompaniesCount());
	}, [dispatch]);

	return (
		Object.keys(companyCount).length !== 0 && (
			<div className="container">
				<span>
					<label htmlFor="company">COMPANY</label>
				</span>
				<div className="company box-design">
					{/* company */}
					<DashboardBox
						title="Companies"
						count={companyCount.totalCompany}
						size="2"
						logo="building-o"
					/>
					<DashboardBox
						title="Active Companies"
						count={companyCount.activeCompany}
						type="vCompanies"
						size="2"
						logo="building-o"
					/>
					<DashboardBox
						title="Verified Companies"
						count={companyCount.verifiedCompany}
						type="vCompanies"
						size="3"
						logo="building-o"
					/>
					<DashboardBox
						title="Unverified Companies"
						count={companyCount.unVerifiedCompany}
						type="uCompanies"
						size="3"
						logo="building-o"
					/>
					<DashboardBox
						title="InActive Companies"
						count={companyCount.deactivatedCompany}
						type="uCompanies"
						size="2"
						logo="building-o"
					/>
					{/* user */}
					<DashboardBox
						title="Active Users"
						count={companyCount.activeUserCount}
						type="vCompanies"
						size="2"
						logo="user-o"
					/>
				</div>
				<span>
					<label htmlFor="company">BOOKING</label>
				</span>
				<div className="booking box-design">
					{/* booking */}
					<DashboardBox
						title="Total Booking"
						count={companyCount.totalBooking}
						type="vCompanies"
						size="2"
						logo="sticky-note"
					/>
					<DashboardBox
						title="Booking With Pax"
						count={companyCount.bookings}
						type="vCompanies"
						size="2"
						logo="sticky-note"
					/>
					<DashboardBox
						title="Average Pax"
						count={companyCount.averagePax}
						type="vCompanies"
						size="2"
						logo="sticky-note"
					/>
					<DashboardBox
						title="Average Rate"
						count={companyCount.averageRate}
						type="vCompanies"
						size="2"
						logo="sticky-note"
					/>
					<DashboardBox
						title="Hold Booking"
						count={companyCount.totalHoldBooking}
						type="uCompanies"
						size="2"
						logo="sticky-note"
					/>
					<DashboardBox
						title="Inquiry Booking"
						count={companyCount.totalInquiryBooking}
						type="uCompanies"
						size="2"
						logo="sticky-note"
					/>
					<DashboardBox
						title="Process Booking"
						count={companyCount.totalProcessBooking}
						type="vCompanies"
						size="2"
						logo="sticky-note"
					/>
					<DashboardBox
						title="Booked Booking"
						count={companyCount.totalBookedBooking}
						type="vCompanies"
						size="2"
						logo="sticky-note"
					/>
					<DashboardBox
						title="Pending Booking"
						count={companyCount.totalPendingBooking}
						type="uCompanies"
						size="2"
						logo="sticky-note"
					/>
					<DashboardBox
						title="Confirmed Booking"
						count={companyCount.totalConfirmedBooking}
						type="vCompanies"
						size="2"
						logo="sticky-note"
					/>
					<DashboardBox
						title="Complete Booking"
						count={companyCount.totalCompleteBooking}
						type="vCompanies"
						size="2"
						logo="sticky-note"
					/>
					<DashboardBox
						title="Canceled Booking"
						count={companyCount.totalCanceledBooking}
						type="uCompanies"
						size="2"
						logo="sticky-note"
					/>
				</div>
				<span>
					<label htmlFor="payment">PAYMENT</label>
				</span>
				<div className="payment box-design">
					{/* payment count*/}
					<DashboardBox
						title="Cash Payment"
						count={companyCount.paymentWithCash}
						type="vCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Cheque Payment"
						count={companyCount.paymentWithCheque}
						type="vCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Cash Refund"
						count={companyCount.paymentWithCashRefund}
						type="uCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Cheque Refund"
						count={companyCount.paymentWithChequeRefund}
						type="uCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Discount"
						count={companyCount.discountPayment}
						type="uCompanies"
						size="2"
						logo="money"
					/>
					{/* payment amount */}
					<DashboardBox
						title="Cash Payment Amount"
						count={`Rs.${companyCount.amountWithCash.toLocaleString(
							undefined,
							{
								maximumFractionDigits: 2,
							}
						)}`}
						type="vCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Cheque Payment Amount"
						count={`Rs.${companyCount.amountWithCheque.toLocaleString(
							undefined,
							{
								maximumFractionDigits: 2,
							}
						)}`}
						type="vCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Cash Refund Amount"
						count={`Rs.${companyCount.amountWithCashRefund.toLocaleString(
							undefined,
							{
								maximumFractionDigits: 2,
							}
						)}`}
						type="uCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Cheque Refund Amount"
						count={`Rs.
					${companyCount.amountWithChequeRefund.toLocaleString(undefined, {
						maximumFractionDigits: 2,
					})}`}
						type="uCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Current Revenue"
						count={`Rs.${companyCount.currentRevenue.toLocaleString(
							undefined,
							{
								maximumFractionDigits: 2,
							}
						)}`}
						type="uCompanies"
						size="2"
						logo="money"
					/>
				</div>
				<span>
					<label htmlFor="vat">VAT</label>
				</span>
				<div className="vat box-design">
					{/* vat payment count */}
					<DashboardBox
						title="Total Vat Count"
						count={companyCount.vatTotalCount}
						type="vCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Created Vat Count"
						count={companyCount.vatCreatedCount}
						type="vCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Billed Vat Count"
						count={companyCount.vatBilledCount}
						type="uCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Completed Vat Count"
						count={companyCount.vatCompletedCount}
						type="uCompanies"
						size="2"
						logo="money"
					/>
					{/* vat payment amount */}
					<DashboardBox
						title="Total Vat Amount"
						count={`Rs.${companyCount.vatTotalAmount.toLocaleString(
							undefined,
							{
								maximumFractionDigits: 2,
							}
						)}`}
						type="vCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Created Vat Amount"
						count={`Rs.${companyCount.vatCreatedAmount.toLocaleString(
							undefined,
							{
								maximumFractionDigits: 2,
							}
						)}`}
						type="vCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Billed Vat Amount"
						count={`Rs.${companyCount.vatBilledAmount.toLocaleString(
							undefined,
							{
								maximumFractionDigits: 2,
							}
						)}`}
						type="uCompanies"
						size="2"
						logo="money"
					/>
					<DashboardBox
						title="Completed Vat Amount"
						count={`Rs.${companyCount.vatCompleteAmount.toLocaleString(
							undefined,
							{
								maximumFractionDigits: 2,
							}
						)}`}
						type="uCompanies"
						size="2"
						logo="money"
					/>
				</div>
			</div>
		)
	);
}
