export const issuePage = [
	{ id: "booking", value: "Booking" },
	{ id: "dashboard", value: "Dashboard" },
	{ id: "settings", value: "Settings" },
];

export const supportType = [
	{ id: "crash", value: "Crash" },
	{ id: "error", value: "Error" },
	{ id: "feature request", value: "Feature Request" },
	{ id: "help", value: "Help" },
];
export const supportStatus = [
	{ id: "open", value: "Open" },
	{ id: "closed", value: "Closed" },
	{ id: "in progress", value: "In Progress" },
];
