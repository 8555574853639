import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useHistory } from "react-router-dom";

// import { loginUser } from "../../redux/index";

import "./login.style.scss";
import { isEmptyObject } from "../../helpers/functions";
import PasswordInput from "../../components/text-input/password-input-error.component";
import TextInput from "../../components/text-input/text-input-error.component";
import { Button } from "@mui/material";
import { loginUser } from "../../redux/actions/login-action";

const loginSchema = yup.object().shape({
	user_email: yup
		.string()
		.required("Email cannot be empty")
		.email("Please enter a valid email"),
	password: yup
		.string()
		.required("Password Required")
		.min(8, "Password Must be atleast 8 Character"),
});

export default function Login() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { user } = useSelector((state) => state.login);
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(loginSchema),
	});

	const userLogin = async (data) => {
		await dispatch(loginUser(data));
	};

	useEffect(() => {
		let isAuth = localStorage.getItem("fooventToken");
		if (isAuth) {
			history.push({
				pathname: "/dashboard",
			});
		}
	}, [history]);

	useEffect(() => {
		if (!isEmptyObject(user)) {
			history.push({
				pathname: "/dashboard",
			});
		}
	}, [history, user]);

	return (
		<div className="login-wrapper">
			<div className="login-container">
				<div className="login-form-container">
					<h2 className="login-title-message">Welcome,</h2>
					<label className="login-subtitle-message">
						Sign In to continue
					</label>
					<Controller
						name="user_email"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<TextInput
								required
								id="outlined-size-small email"
								className="login-input"
								label="Email"
								type="email"
								field={field}
								errormessage={errors?.user_email?.message}
							/>
						)}
					/>
					<Controller
						name="password"
						control={control}
						defaultValue=""
						render={({ field }) => (
							<PasswordInput
								label="Password"
								field={field}
								errormessage={errors?.password?.message}
							/>
						)}
					/>
					<span className="forgot-pw-container">
						Forgot Password?
					</span>

					<Button
						variant="contained"
						color="primary"
						className="login-button"
						onClick={handleSubmit(userLogin)}
					>
						Login
					</Button>

					{/* <span className="register-container">
						<Link to="/signup" className="register-container">
							I'm new user. Register
						</Link>
					</span> */}
				</div>
			</div>
		</div>
	);
}
