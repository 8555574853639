import React from "react";
import "./Sidebar.scss";
import PersonIcon from "@mui/icons-material/Person";
import ForumOutlinedIcon from "@mui/icons-material/ForumOutlined";
// import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";

const SideNavigation = () => {
	// const [toggle, setToggle] = useState(true);
	// function toggleSidebar() {
	// 	let sideNav = document.getElementById("sideNavWrapper");
	// 	if (toggle) {
	// 		sideNav.style.width = "250px";
	// 		setToggle(false);
	// 	} else {
	// 		sideNav.style.width = "70px";
	// 		setToggle(true);
	// 	}
	// }

	return (
		<div className="side-navigation-wrapper" id="sideNavWrapper">
			<div className="side-menu-container">
				<Link to="/dashboard">
					<div className="menu-container">
						<PersonIcon className="menu-icon" />
						<span className="menu-label">Dashboard</span>
					</div>
				</Link>
				<Link to="/winloss">
					<div className="menu-container">
						<PersonIcon className="menu-icon" />
						<span className="menu-label">Win Loss</span>
					</div>
				</Link>
				<Link to="/company">
					<div className="menu-container">
						<ForumOutlinedIcon className="menu-icon" />
						<span className="menu-label">Company</span>
					</div>
				</Link>
				<Link to="/support">
					<div className="menu-container">
						<ForumOutlinedIcon className="menu-icon" />
						<span className="menu-label">Support Issues</span>
					</div>
				</Link>
			</div>
		</div>
	);
};
export default SideNavigation;
