import axios from "axios";

import { GET_ALL_WIN_LOSS } from "../../constants/endpoints";

import {
	FETCH_WINLOSS_REQUEST,
	FETCH_WINLOSS_SUCCESS,
	FETCH_WINLOSS_FAILURE,
	// UPDATE_WIN_LOSS,
} from "../action-types";

import { sessionInfo } from "../../helpers/functions";
import setAuthToken from "../../helpers/setAuthToken";

export const getAllWinloss = () => async (dispatch) => {
	const sessionData = await sessionInfo();

	if (localStorage.fooventLoggedIn) {
		setAuthToken(sessionData.token);
	}

	dispatch({
		type: FETCH_WINLOSS_REQUEST,
	});

	try {
		const response = await axios.get(GET_ALL_WIN_LOSS);

		dispatch({
			type: FETCH_WINLOSS_SUCCESS,
			payload: response.data.result,
		});
	} catch (err) {
		dispatch({
			type: FETCH_WINLOSS_FAILURE,
			error: err.message,
		});
	}
};
